.nav-brand{
    font-family: 'Poppins', sans-serif;
    font-size: 25px;
    font-weight: 600;
    -webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

}
@-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  

.nav-items{
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}
@media (max-width:800px) {
    .nav-items{
        display: none;
    }
}
.list-disp{
    display: none;
}
.list-disp1{
    max-width:100% !important;
    height: 100% !important;
}
.nav-item{
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    text-decoration: none;
    margin:5px;
    font-size: large;
    color: #273444;
}
.nav-item:hover{
    color: rgba(212, 29, 29, 0.603);
}
.toggle-btn{
   display: none;
}
@media(max-width: 800px) {
    .toggle-btn{
        display: block;
        /* font-size: 30px; */
        color: rgb(0, 0, 0);
        background: transparent;
        border: none;
         cursor: pointer;
        outline: none;
        float: right;
        
    }
}
/* Hero */
.hero{
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url(../assets/heroimg.svg);
    background-size: cover;
    padding-bottom: 10vh;
}
 @media (max-width: 800px) {
    .nav-items{
        display: none;
    }
} 
.hero-item{
    margin: 10px;
    text-align:center;
    min-width:45vw;
    padding-left: 30px;
    align-self: center;
    min-height: 45vh;
}
.hero-img{
    align-items: center;
    height:250px;
    width: 200px;
    margin: auto auto;
}
.hero-img:hover{
    align-items: center;
    height:300px;
    width: 250px;
    margin: auto auto;
    padding: 20px;
    
}
@media (max-width: 800px) {
    .hero{
        color: white;
    }
}
#hero-button{
    min-width: 100px;
    margin-top: 10px;
    background-color: #273444;
    color: #ffffff;
    
}
#himg{
    margin: auto auto;
   
}
.hero-text{
    margin: auto auto;
}
.hero-separator{
    border-top: 3px solid ;
    border-radius: 5px;
    margin-top: 0%;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    
    }
    
/* Footer */
.footer{
    display:flex;
    flex-direction: row;
    background-color: #273444;
    margin-top: 20px;
    flex-wrap: wrap;
    min-height: 25vh;
    
}
.socialImg {
    filter: brightness(0) invert(1);
  }

.socialImg{
    height: 0.85cm;
    width: 0.85cm;
}
.footer-items{
margin: auto;
color: rgb(255, 255, 255);
text-align: center;
}
.footer-items:first-child{
    width: 300px;
}

.footer-text{
    text-align: center;
    color: rgb(255, 255, 255);
    background-color:#273444 ;
}
/* Vision */
.Vmain{
    margin:30px;
    
}
.Vcards{
    display: flex;
    flex-direction: row;
    margin: auto;
    justify-content:space-around;
    align-items: flex-start;

    

}
@media (max-width: 800px) {
    .Vcards{
        display: flex;
        flex-direction: column;
    }
}
.V-items{
    
    align-items: flex-start;
    border-radius: 2%;
    height: 21rem;
    padding: 20 px;
    margin: auto;
    text-align: center;
    justify-content: center;
    border-top: groove 1px;
    border-left: groove  1px;

}
.Vi{
    margin: 10px 20px 20px 20px;
   /*  border: solid 1px; */
    min-height: 50vh;
    padding: 10px;
    max-width: 300px;
    box-shadow: 10px 10px 10px grey;
    
}
.Vlogo1{
    width: 80px;
    height: 80px;
    margin-bottom: 5vh;
}
.Vlogo{
    width: 50px;
    height: 70px;
    margin-bottom: 5vh;
}
/* AboutUs */
.aboutus{
    text-align: center;
    min-height: 50vh;
    padding-top: 10vh;
    
}
.abt-logo{
    height: 3.5rem;
    width: 3rem;
    margin-bottom: 2.5rem;
}
.about-card{
    /* font-size: large; */
    font-family:sans-serif;
    min-width: 70vw;
    border-radius: 10px;
    margin: auto;
    margin-top: 20px;
    padding: 10vh;
    max-width: 300px;
    box-shadow: 10px 10px 10px grey;
    border-top: groove 1px;
    border-left: groove  1px;
}
@media (max-width: 800px) {
    .about-card{
       
        padding: 1.5rem;
        
    }
}

/* Whatwedo */
.Wmain{
    margin:30px;
    border-radius: 10px;
    text-align: center;
    padding-top: 10vh;
}
.Wcards{
    display: flex;
    flex-direction: row;
    margin: auto;
    justify-content:space-around;
    align-items: flex-start;
    border-radius: 10px;
    padding-top: 5vh;
    padding-left: 3vw;
    padding-right: 3vw;
    

}
@media (max-width: 800px) {
    .Wcards{
        display: flex;
        flex-direction: column;
    }
}
.Wc-items{
    
    align-items: flex-start;
    min-height: 50vh;
    padding: 20 px;
    margin: auto;
    text-align: center;
    justify-content: center;

}
.ci{
    margin: 10px 20px 20px 20px;
    min-height: 30vh;
    padding: 10px;
    max-width: 300px;
    box-shadow: 10px 10px 10px grey;
    border-radius: 10px;
    border-top: groove 1px;
    border-left: groove  1px;
    
}
.cphoto{
    width: 250px;
    height: 200px;
    margin-bottom: 5vh;
    border-radius: 10px;
}
.nav-brand{
    font-family: 'Poppins', sans-serif;
    font-size: 25px;
    font-weight: 600;
    -webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

}
@-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
/* Gallary */
.Gallary{
      text-align: center;
  }
#G-para{
    color: #5b6775;
    font-size: larger;
}
.Gallary-cards{
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin: auto;
    text-align: center;
    justify-content: center;
}
@media (max-width: 800px) {
    .Gallary-cards{
        display: flex;
        flex-direction: column;
}
}

.gallary-img{
    width: 20rem;
    /* height: 10rem; */
}